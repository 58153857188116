@-webkit-keyframes drop {
  0% {
    transform: rotate(45deg) scale(0%, 0%);
  }
  100% {
    transform: rotate(45deg) scale(100%, 100%);
  }
}
@keyframes drop {
  0% {
    transform: rotate(45deg) scale(0%, 0%);
  }
  100% {
    transform: rotate(45deg) scale(100%, 100%);
  }
}
@-webkit-keyframes open {
  0% {
    transform: scaleY(0%);
  }
  100% {
    transform: scaleY(100%);
  }
}
@keyframes open {
  0% {
    transform: scaleY(0%);
  }
  100% {
    transform: scaleY(100%);
  }
}
@-webkit-keyframes reveal {
  0% {
    transform: scale(0%, 0%);
  }
  80% {
    transform: scale(130%, 130%);
  }
  100% {
    transform: scale(100%, 100%);
  }
}
@keyframes reveal {
  0% {
    transform: scale(0%, 0%);
  }
  80% {
    transform: scale(130%, 130%);
  }
  100% {
    transform: scale(100%, 100%);
  }
}
* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Kanit", sans-serif;
  color: white;
}

body {
  background-color: black;
  min-width: 800px;
}
body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
body::-webkit-scrollbar-track-piece {
  margin: 20px;
}
body::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 94vw;
  height: 400px;
  position: relative;
  min-width: 800px;
  background-color: rgb(50, 50, 50);
  border: inset 10px rgb(150, 0, 0);
  border-radius: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 0 3vw;
}

button {
  border-radius: 5px;
}

.modal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

/**********/
/* Navbar */
/**********/
.navbar {
  width: 100%;
  height: 60px;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  background-color: transparent;
  padding: 0 4vw;
  margin: 2vh 0;
  z-index: 5;
}
.navbar .nav-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-self: flex-start;
  z-index: 12;
}
.navbar .nav-search-container .nav-search {
  width: 200px;
  height: 30px;
  background-color: rgb(39, 39, 39);
  border: 2px inset rgb(150, 0, 0);
  border-radius: 10px;
  outline: none;
  color: white;
  padding-left: 10px;
}
.navbar .nav-search-container .nav-search-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  padding: 5px;
  background-color: rgb(39, 39, 39);
  margin-left: 10px;
  cursor: pointer;
  z-index: 5;
}
.navbar .nav-search-container .nav-search-button > img {
  width: auto;
  height: 100%;
}
.navbar .nav-title {
  text-align: center;
  color: rgb(150, 0, 0);
  text-shadow: 2px 2px rgb(102, 102, 102);
  font-size: 30px;
}
.navbar .nav-search-menus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  min-width: 100px;
}
.navbar .nav-search-menus div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.navbar .nav-search-menus div h3 {
  font-weight: 500;
  margin-right: 50px;
  cursor: default;
}
.navbar .nav-search-menus .friends-menu-icon {
  width: 30px;
  height: 30px;
  margin-right: 50px;
  cursor: pointer;
}
.navbar .nav-search-menus .nav-menu-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/***************/
/* SearchModal */
/***************/
@media (max-width: 1000px) {
  .search-modal .modal-list {
    width: 450px;
    height: 80%;
    padding: 10px 20px;
  }
}
@media (min-width: 1001px) {
  .search-modal .modal-list {
    width: 960px;
    height: 80%;
    padding: 20px 30px;
  }
}
.search-modal .modal-list {
  position: fixed;
  top: calc(2vh + 50px + 30px);
  left: 3vw;
  display: grid;
  align-items: flex-start;
  background-color: rgb(102, 102, 102);
  border-radius: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-animation: open 0.4s ease forwards;
          animation: open 0.4s ease forwards;
  transform-origin: 50% -30px;
  z-index: 12;
}
.search-modal .modal-list::-webkit-scrollbar {
  width: 10px;
}
.search-modal .modal-list::-webkit-scrollbar-track-piece {
  margin: 20px;
}
.search-modal .modal-list::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}
@media (max-width: 1000px) {
  .search-modal .modal-drop-down {
    left: 80px;
  }
}
@media (max-width: 1800px) and (min-width: 1001px) {
  .search-modal .modal-drop-down {
    left: 8vw;
  }
}
@media (min-width: 1801px) {
  .search-modal .modal-drop-down {
    left: 7vw;
  }
}
.search-modal .modal-drop-down {
  width: 60px;
  height: 60px;
  position: fixed;
  top: calc(2vh + 50px);
  background-color: rgb(102, 102, 102);
  border-radius: 5px;
  -webkit-animation: drop 0.4s ease forwards;
          animation: drop 0.4s ease forwards;
  transform-origin: top left;
  z-index: 11;
}

/***************/
/* SearchTiles */
/***************/
@media (max-width: 1000px) {
  .search-tiles {
    width: 400px;
    display: grid;
    grid-template-columns: 100px 290px;
    grid-template-rows: 150px 50px;
    -moz-column-gap: 10px;
         column-gap: 10px;
    margin: 10px 0;
  }
  .search-tiles .st-poster-container {
    width: 100%;
    height: auto;
    grid-row-start: 1;
    grid-column-start: 1;
  }
  .search-tiles .st-poster-container img {
    width: 100%;
    height: auto;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 3px;
  }
  .search-tiles .st-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    grid-row-start: 1;
    grid-column-start: 2;
  }
  .search-tiles .st-info .st-header {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
  }
  .search-tiles .st-info .st-header .st-title {
    font-size: 15px;
    line-height: 1;
  }
  .search-tiles .st-info .st-header .st-date {
    font-size: 10px;
    color: rgb(180, 180, 180);
    min-width: 100px;
    font-weight: 500;
  }
  .search-tiles .st-info h5 {
    font-size: 12px;
    font-weight: 500;
  }
  .search-tiles .st-info .st-overview {
    width: 100%;
    height: auto;
    overflow-y: auto;
    font-size: 12px;
    font-weight: 300;
    grid-row-start: 2;
    grid-column: 1/3;
  }
  .search-tiles .st-info .st-overview::-webkit-scrollbar {
    width: 5px;
  }
  .search-tiles .st-info .st-overview::-webkit-scrollbar-thumb {
    background-color: rgb(190, 190, 190);
    border-radius: 5px;
  }
  .search-tiles .st-watched,
.search-tiles .st-button-container {
    grid-row-start: 2;
    grid-column: 1/3;
  }
  .search-tiles .st-watched {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
  }
  .search-tiles .st-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .search-tiles .st-button-container .search-tb-watched {
    background-color: green;
  }
  .search-tiles .st-button-container .search-tb-unwatched {
    background-color: red;
  }
  .search-tiles .st-button-container .search-tb-watched,
.search-tiles .st-button-container .search-tb-unwatched {
    width: 170px;
    height: 30px;
    margin: 10px 0;
    cursor: pointer;
  }
  .search-tiles .st-button-container .search-tb-watched:hover,
.search-tiles .st-button-container .search-tb-unwatched:hover {
    transform: translate(-1px, -1px);
    box-shadow: 1px 1px 3px black;
  }
  .search-tiles .st-button-container .search-tb-watched:active,
.search-tiles .st-button-container .search-tb-unwatched:active {
    transform: translate(1px, 1px);
    box-shadow: inset 1px 1px 3px black;
  }
}
@media (min-width: 1001px) {
  .search-tiles {
    width: 100%;
    height: 230px;
    display: grid;
    grid-template-columns: 153px 538px 200px;
    margin: 10px 0;
  }
  .search-tiles .st-poster-container {
    width: 100%;
    height: 100%;
  }
  .search-tiles .st-poster-container img {
    width: 100%;
    height: 230px;
    -o-object-fit: contain;
       object-fit: contain;
    border-radius: 5%;
  }
  .search-tiles .st-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 500px;
    height: 100%;
    margin: 0 20px;
  }
  .search-tiles .st-info .st-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .search-tiles .st-info .st-header .st-title {
    font-size: 20px;
    line-height: 1;
  }
  .search-tiles .st-info .st-header .st-date {
    font-size: 15px;
    margin-left: 20px;
    color: rgb(180, 180, 180);
    min-width: 120px;
    font-weight: 500;
    text-align: center;
  }
  .search-tiles .st-info h5 {
    color: rgb(197, 197, 197);
    font-weight: 500;
  }
  .search-tiles .st-info .st-overview {
    width: 100%;
    height: 170px;
    overflow-y: auto;
    font-weight: 300;
    padding-right: 5px;
  }
  .search-tiles .st-info .st-overview::-webkit-scrollbar {
    width: 7px;
  }
  .search-tiles .st-info .st-overview::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  .search-tiles .st-info .st-overview::-webkit-scrollbar-thumb {
    background-color: rgb(190, 190, 190);
    border-radius: 5px;
  }
  .search-tiles .st-watched {
    width: 200px;
    font-size: 15px;
  }
  .search-tiles .st-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
  }
  .search-tiles .st-button-container .search-tb-watched {
    background-color: green;
  }
  .search-tiles .st-button-container .search-tb-unwatched {
    background-color: red;
  }
  .search-tiles .st-button-container .search-tb-watched,
.search-tiles .st-button-container .search-tb-unwatched {
    width: 200px;
    height: 30px;
    margin: 10px 0;
    cursor: pointer;
  }
  .search-tiles .st-button-container .search-tb-watched:hover,
.search-tiles .st-button-container .search-tb-unwatched:hover {
    transform: translate(-1px, -1px);
    box-shadow: 1px 1px 3px black;
  }
  .search-tiles .st-button-container .search-tb-watched:active,
.search-tiles .st-button-container .search-tb-unwatched:active {
    transform: translate(1px, 1px);
    box-shadow: inset 1px 1px 3px black;
  }
}
/***********/
/* Watched */
/***********/
.watched .watched-title {
  position: absolute;
  transform: translate(-50%, -50%) rotate(-90deg);
  top: 50%;
  left: 20px;
  line-height: 1;
  font-size: 20px;
  color: rgb(90, 90, 90);
  text-shadow: -2px 0px rgb(10, 10, 10);
}
.watched .watched-tile-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 226.66px);
  background-color: rgb(50, 50, 50);
  border-radius: 20px;
  padding: 20px 10px;
  margin: 0 15px 0 40px;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 2%;
}
.watched .watched-tile-container::-webkit-scrollbar {
  height: 10px;
}
.watched .watched-tile-container::-webkit-scrollbar-track {
  margin: 10px;
}
.watched .watched-tile-container::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

/*************/
/* Unwatched */
/*************/
.unwatched {
  margin-top: 2vh;
}
.unwatched .unwatched-title {
  position: absolute;
  transform: translate(-50%, -50%) rotate(-90deg);
  top: 50%;
  left: 20px;
  line-height: 1;
  font-size: 20px;
  color: rgb(90, 90, 90);
  text-shadow: -2px 0px rgb(10, 10, 10);
}
.unwatched .unwatched-tile-container {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 226.66px);
  background-color: rgb(50, 50, 50);
  border-radius: 20px;
  padding: 20px 10px;
  margin: 0 15px 0 40px;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 2%;
}
.unwatched .unwatched-tile-container::-webkit-scrollbar {
  height: 10px;
}
.unwatched .unwatched-tile-container::-webkit-scrollbar-track {
  margin: 10px;
}
.unwatched .unwatched-tile-container::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

/********/
/* Tile */
/********/
.tiles {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 340px;
  grid-row: 1;
  cursor: pointer;
}
.tiles .tiles-poster {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 5%;
  box-shadow: 5px 5px 10px black;
}
.tiles .tiles-poster:hover {
  transform: translate(-2px, -2px);
  box-shadow: 7px 7px 15px black;
}
.tiles .tiles-poster:active {
  transform: translate(1px, 1px);
  box-shadow: 5px 5px 10px black;
}

/***************/
/* DetailModal */
/***************/
@media (max-width: 650px) {
  .detail-modal {
    width: 450px;
    height: 450px;
  }
  .detail-modal .dm-buttons {
    bottom: 2%;
  }
}
@media (min-width: 651px) {
  .detail-modal {
    width: 600px;
    height: 600px;
  }
  .detail-modal .dm-buttons {
    bottom: 7%;
  }
}
.detail-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: rgb(102, 102, 102);
  padding: 30px;
  border-radius: 20px;
  z-index: 10;
}
.detail-modal .dm-poster {
  width: auto;
  height: 40%;
}
.detail-modal .dm-title {
  font-size: 20px;
  text-align: center;
}
.detail-modal .dm-date {
  font-size: 15px;
  color: rgb(197, 197, 197);
}
.detail-modal h5 {
  color: rgb(197, 197, 197);
  font-weight: 500;
}
.detail-modal .dm-overview {
  font-size: 15px;
  height: 35%;
  margin: 0 30px 0px 30px;
  overflow-y: auto;
  font-weight: 500;
}
.detail-modal .dm-overview::-webkit-scrollbar {
  width: 7px;
}
.detail-modal .dm-overview::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}
.detail-modal .dm-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 600px;
  height: auto;
  position: absolute;
}
.detail-modal .dm-buttons .dm-move {
  background-color: green;
}
.detail-modal .dm-buttons .dm-remove {
  background-color: red;
}
.detail-modal .dm-buttons .dm-move,
.detail-modal .dm-buttons .dm-remove {
  margin: 0 10px;
  cursor: pointer;
}
.detail-modal .dm-buttons .dm-move:hover,
.detail-modal .dm-buttons .dm-remove:hover {
  transform: translate(-1px, -1px);
  box-shadow: 1px 1px 3px black;
}
.detail-modal .dm-buttons .dm-move:active,
.detail-modal .dm-buttons .dm-remove:active {
  transform: translate(1px, 1px);
  box-shadow: inset 1px 1px 3px black;
}

/*************/
/* LoginMenu */
/*************/
.login-menu {
  position: fixed;
  top: calc(2vh + 65px);
  right: 3.5vw;
  z-index: 5;
}
.login-menu .lm-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 70px;
  right: 40px;
  background-color: rgb(102, 102, 102);
  width: 250px;
  border-radius: 10px;
  -webkit-animation: open 0.4s ease forwards;
          animation: open 0.4s ease forwards;
  transform-origin: 50% -20px 20px;
  padding: 15px;
}
.login-menu .lm-info input {
  border: solid 2px black;
  border-radius: 5px;
  background: rgb(65, 65, 65);
  outline: none;
  margin: 5px 0;
  padding-left: 5px;
}
.login-menu .lm-info button {
  width: 118px;
  height: auto;
  font-size: 13px;
  margin: 5px 0;
  background-color: rgb(65, 65, 65);
  border-radius: 5px;
  cursor: pointer;
}
.login-menu .lm-info button:hover {
  transform: translate(-1px, -1px);
  box-shadow: 1px 1px 3px black;
}
.login-menu .lm-info button:active {
  transform: translate(1px, 1px);
  box-shadow: inset 1px 1px 3px black;
}
.login-menu .lm-info .display {
  -webkit-animation: reveal 0.1s ease-in forwards;
          animation: reveal 0.1s ease-in forwards;
}
.login-menu .lm-info .lm-drop-down {
  width: 30px;
  height: 30px;
  position: fixed;
  top: -12px;
  right: 10px;
  border-radius: 5px;
  transform: rotate(45deg);
  background-color: rgb(102, 102, 102);
}
.login-menu .lm-info h1 {
  text-align: center;
  font-size: 20px;
  cursor: default;
}
.login-menu .lm-info .unwatched-count {
  margin-bottom: 10px;
}
.login-menu .lm-info .lm-incorrect {
  pointer-events: none;
  opacity: 0;
}
.login-menu .lm-info .lm-incorrect.reveal {
  color: rgb(170, 0, 0);
  opacity: 1;
  -webkit-animation: reveal 0.1s ease-in forwards;
          animation: reveal 0.1s ease-in forwards;
}
.login-menu .lm-info .lm-change-incorrect,
.login-menu .lm-info .lm-delete-incorrect {
  pointer-events: none;
  opacity: 0;
}
.login-menu .lm-info .lm-change-incorrect.reveal,
.login-menu .lm-info .lm-delete-incorrect.reveal {
  color: rgb(170, 0, 0);
  opacity: 1;
  -webkit-animation: reveal 0.1s ease-in forwards;
          animation: reveal 0.1s ease-in forwards;
}
.login-menu .lm-info .lm-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-menu .lm-info .lm-login-container .lm-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-menu .lm-info .lm-login-container h5 {
  color: white;
  text-align: center;
  margin: 5px 0;
}
.login-menu .lm-info .lm-signout-container {
  display: grid;
  grid-template-rows: repeat(3, auto);
  align-items: center;
  justify-items: center;
}
.login-menu .lm-info .lm-signout-container .lm-change-pw-form,
.login-menu .lm-info .lm-signout-container .lm-delete-account-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  padding: 5px 0;
  margin: 5px 0;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
}
.login-menu .lm-info .lm-signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-menu .lm-info .lm-signup-container .lm-signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login-menu .lm-info .lm-signup-container h5 {
  color: white;
  text-align: center;
  margin-top: 10px;
}

/***************/
/* FriendsMenu */
/***************/
.fm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 3;
}

.fm-info-container {
  position: fixed;
  top: 70px;
  right: 100px;
  border-radius: 10px;
  -webkit-animation: open 0.4s ease forwards;
          animation: open 0.4s ease forwards;
  transform-origin: 50% -20px 20px;
  background-color: rgb(102, 102, 102);
  z-index: 4;
}

.fm-info {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: calc(100vh - 70px);
  width: 400px;
  overflow-y: auto;
  display: grid;
  grid-template-rows: repeat(auto, 100px);
  gap: 5px;
  border-radius: 10px;
  padding: 15px;
}

.fm-info::after {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  transform: rotate(45deg);
  background-color: rgb(102, 102, 102);
  right: 30px;
  top: -12px;
  z-index: -1;
}

.fm-info::-webkit-scrollbar {
  width: 7px;
}

.fm-info::-webkit-scrollbar-track {
  border-radius: 5px;
  margin: 20px 0;
}

.fm-info::-webkit-scrollbar-thumb {
  background-color: rgb(190, 190, 190);
  border-radius: 5px;
}

.fm-add-friend {
  bottom: 0;
  left: 0;
  height: 100px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: rgb(50, 50, 50);
}

.fm-add-friend:hover {
  transform: translate(-2px, -2px);
  box-shadow: 2px 2px 2px black;
}

/***************/
/* FriendTiles */
/***************/
.friend-tiles {
  width: 100%;
  height: 100px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 100px 1fr 50px;
  padding: 5px;
  align-items: center;
  justify-items: center;
  border: solid 1px rgb(61, 61, 61);
  border-radius: 10px;
  background-color: rgb(50, 50, 50);
}

.friend-tiles:hover {
  transform: translate(-2px, -2px);
  box-shadow: 2px 2px 2px black;
}

.ft-profile-pic {
  height: 75px;
  width: 75px;
  border-radius: 50%;
}

.ft-info {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.ft-info h1 {
  font-size: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ft-info h5 {
  font-weight: 300;
}

.ft-unfriend {
  height: 30px;
  width: 30px;
  border-radius: 7px;
}

.ft-unfriend:hover {
  transform: translate(-1px, -1px);
  box-shadow: 1px 1px 3px black;
}/*# sourceMappingURL=App.css.map */